"use strict";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
/**
 * Created by Leonardo B on 02/10/2018.
 */

(function () {
  'use strict';

  function dependencies(window) {
    return {
      $: window.jQuery,
      createAndShowNotification: window.createAndShowNotification,
      getClearUniqueArray: window.getClearUniqueArray,
      getUniID: window.getUniID,
      globalFilter: window.globalFilter
    };
  }
  var _dependencies = dependencies(window),
    $ = _dependencies.$,
    createAndShowNotification = _dependencies.createAndShowNotification,
    getClearUniqueArray = _dependencies.getClearUniqueArray,
    getUniID = _dependencies.getUniID,
    globalFilter = _dependencies.globalFilter;
  var tagsManager = {
    // new place to store universeTagging
    universeTagging: [],
    // Max number of retries to fetch tags
    maxRetries: 5,
    minLengthTag: 2,
    /**
     * This is a legacy method for annotation_rules.js
     * @param $tagsSelector
     * @param allowTagCreation
     * @param allowTagNegation
     * @param useAjax
     * @param startValuesFunction
     * @param callbackSelect
     * @param callbackUnselect
     * @returns {*}
     */
    createTagsSelector: function createTagsSelector($tagsSelector, _ref, _ref2) {
      var allowTagCreation = _ref.allowTagCreation,
        allowTagNegation = _ref.allowTagNegation,
        useAjax = _ref.useAjax;
      var startValuesFunction = _ref2.startValuesFunction,
        callbackSelect = _ref2.callbackSelect,
        callbackUnselect = _ref2.callbackUnselect;
      if (!$tagsSelector) return false;
      var selectId = $tagsSelector[0].id;
      var select2Configs = {
        //custom attribute to allow negation of tags on filter
        allowNegationOnClick: allowTagNegation,
        elementId: $tagsSelector[0].id,
        //end custom attrs
        tags: allowTagCreation,
        tokenSeparators: [','],
        data: getClearUniqueArray(tagsManager.universeTagging),
        placeholder: 'Digite uma tag',
        closeOnSelect: true,
        language: {
          noResults: function noResults() {
            return 'Sem resultados';
          },
          searching: function searching() {
            return 'Buscando…';
          },
          errorLoading: function errorLoading() {
            return 'Sem resultados';
          }
        }
      };
      var ajax = false;
      if (useAjax) {
        ajax = {
          url: '/gettags',
          dataType: 'json',
          data: function data(params) {
            if (!getUniID() && window.uni_id) {
              params.uni_id = window.uni_id;
            }
            return params;
          },
          cache: true,
          delay: 300,
          processResults: function processResults(data, params) {
            if (data.status === 'OK') {
              if (allowTagNegation) {
                $(data.message).each(function (_, val) {
                  data.message.push({
                    text: "-".concat(val.text),
                    id: "-".concat(val.id)
                  });
                });
              }
              if (!data.message) return {
                results: [params.term]
              };
              data.message.push({
                text: 'Sem Tags',
                id: 'Sem Tags'
              });
              var results = [];
              $(data.message).each(function (_, val) {
                if (val.text.toLowerCase().indexOf(params.term.toLowerCase()) > -1 || val.text.removeDiacritics().toLowerCase().indexOf(params.term.toLowerCase()) > -1) {
                  results.push(val);
                }
              });
              return {
                results: results
              };
            }
            return {
              results: []
            };
          }
        };
        select2Configs.ajax = ajax;
      }
      if (allowTagCreation) {
        select2Configs.createTag = function (tag) {
          // tags should not have quotes
          var text = tag.term.replaceAll('"', '');
          return {
            id: text,
            text: text,
            isNew: true
          };
        };
      }
      $tagsSelector.select2(select2Configs).off('select2:select').on('select2:select', function (e) {
        if (callbackSelect) {
          callbackSelect();
        } else {
          globalFilter.addTagToFilterTag(selectId, e, undefined, 'Digite uma tag');
        }
      }).off('select2:unselect').on('select2:unselect', function (e) {
        if (callbackUnselect) {
          callbackUnselect();
        } else {
          var tag = e.params.data.id.trim();
          $('selectId').siblings('.bottom-part').find('div').find("[title=\"".concat(tag, "\"]")).remove();
        }
      });
      var valuesFunction = startValuesFunction;
      if (!startValuesFunction || typeof startValuesFunction !== 'function') {
        valuesFunction = function valuesFunction($tagsSelector) {
          var initialValues = $tagsSelector.attr('value');
          initialValues = getClearUniqueArray(initialValues);
          if (initialValues) {
            $tagsSelector.val(initialValues).trigger('change');
          }
          for (var i in initialValues) {
            if (Object.prototype.hasOwnProperty.call(initialValues, i)) {
              globalFilter.addTagToFilterTag('#filter-global-tags', undefined, initialValues[i], 'Digite uma tag');
            }
          }
        };
      }
      valuesFunction($tagsSelector);
      return $tagsSelector;
    },
    /**
     * this function is called at base.html to starts the universeTagging variavel
     * @param retries
     */
    getUniverseTagging: function getUniverseTagging() {
      var retries = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var isTagsLoaded = tagsManager.universeTagging && tagsManager.universeTagging.length > 0;
      if (isTagsLoaded || retries > this.maxRetries) {
        return;
      }
      $.ajax({
        url: '/gettags',
        success: function success(response) {
          var tags = [];
          if ('status' in response && response.status === 'OK') {
            for (var index in response.message) {
              if (Object.prototype.hasOwnProperty.call(response.message, index)) {
                var tagName = response.message[index].text;
                tags.push(tagName);
              }
            }
            tagsManager.universeTagging = getClearUniqueArray(tags);
          } else {
            tagsManager.getUniverseTagging(retries + 1);
          }
        }
      });
    },
    checkTag: function checkTag(tag) {
      var test = tag.replace(/[\t-\r"\\]/gi, '').trim();
      if (test.length > 0) {
        return test;
      }
      return false;
    },
    // eslint-disable-next-line complexity,max-params
    insertTagInUniverse: function insertTagInUniverse(newTag, select2Instance, ulId, isZoom, isPerformancePosts, callback, callbackParameters) {
      var can_annotate_post_permission = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : window.can_annotate_post;
      var container = isZoom ? parent : window;

      // Here we check if the user have the right permissions to insert new tags in the universe
      var cannot_annotate_post_permission = typeof can_annotate_post_permission === 'boolean' && !can_annotate_post_permission;
      if (!window.canManageTags || cannot_annotate_post_permission) {
        var msg = 'Você não tem permissão para criar tags.';
        if (!can_annotate_post_permission) {
          msg = 'Você não possui permissão para realizar esse tipo de ação.';
        }
        container.createAndShowNotification(msg, 'warning');
        if (select2Instance) this.tagInsertionFailed(newTag, select2Instance);
        return;
      }
      if (!this.isNewTagValid(newTag)) {
        this.tagInsertionFailed(newTag, select2Instance);
        return;
      }
      var newClearedTag = newTag.trim();
      if (isPerformancePosts) {
        // generates a random id to control the button binds
        // eslint-disable-next-line
        var idContent = Math.random().toString(36).substr(2, 9);
        var html2Append = "\n\t\t\t\t<div id='confirm-add-".concat(idContent, "' class='swal-content-performance'>\n\t\t\t\t\t<h2 class='swal2-title performance'>Aten\xE7\xE3o!</h2>\n\t\t\t\t\t<div id='swal2-content' class='swal2-content'>\n\t\t\t\t\t\t<span>Voc\xEA est\xE1 prestes a criar a tag \"<b>").concat(newClearedTag, "</b>\".<br>Gostaria de continuar?</span>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class='swal2-buttonswrapper performance'>\n\t\t\t\t\t\t<div class='swal2-buttonswrapper performance'>\n\t\t\t\t\t\t\t<button id='button-confirm' type='button' class='swal2-confirm dialog-button dialog-primary-button m-r-15'>Sim! Crie a tag!</button>\n\t\t\t\t\t\t\t<button id='button-cancel' type='button' class='swal2-cancel dialog-button dialog-negative-button'>N\xE3o, me tire daqui.</button>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t");
        $(ulId).parent().append(html2Append);
        var $element = $("#confirm-add-".concat(idContent));
        $element.find('#button-confirm').click(function () {
          $element.remove();
          tagsManager.insertNewTagAjax(newClearedTag, select2Instance, isZoom, callback, callbackParameters);
        });
        $element.find('#button-cancel').click(function () {
          $element.remove();
        });
      } else {
        return new Promise(function (resolve) {
          container.swal({
            title: 'Atenção!',
            html: "Voc\xEA est\xE1 prestes a criar a tag \"<b>".concat(newClearedTag, "</b>\". Gostaria de continuar?"),
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: 'Sim! Crie a tag!',
            cancelButtonText: 'Não, me tire daqui.',
            confirmButtonClass: 'dialog-button dialog-primary-button m-r-15',
            cancelButtonClass: 'dialog-button dialog-negative-button',
            allowOutsideClick: false
          }).then(function () {
            tagsManager.insertNewTagAjax(newClearedTag, select2Instance, isZoom, callback, callbackParameters);
            resolve(true);
          }, function (dismiss) {
            if ((dismiss === 'cancel' || dismiss === 'esc') && select2Instance) {
              tagsManager.tagInsertionFailed(newClearedTag, select2Instance);
              return false;
            }
            resolve(false);
          });
        });
      }
    },
    tagInsertionFailed: function tagInsertionFailed(newTag, select2Instance) {
      var val = $(select2Instance).val();
      if (!val) {
        return;
      }
      for (var i = 0; i < val.length; i++) {
        if (val[i].trim() === newTag) {
          val.splice(i, 1);
        }
      }
      $(select2Instance).val(val).change();
    },
    // eslint-disable-next-line max-params
    insertNewTagAjax: function insertNewTagAjax(newTag, select2Instance, isZoom, callback, callbackParameters) {
      var container = isZoom ? parent : window;
      var failMessage = "Ops, n\xE3o conseguimos criar sua nova tag <b style=\"color:black;\">".concat(newTag, "</b>.");
      $.ajax({
        type: 'post',
        url: '/inserttags',
        data: {
          newtag: newTag
        },
        // eslint-disable-next-line complexity
        success: function success(result) {
          if (result.status === 'OK') {
            container.universeTagging.push(newTag);

            // Careful changing here
            container.universeTagging = getClearUniqueArray(parent.universeTagging);

            // Updating universeTagging array in tagsManager
            container.tagsManager.universeTagging.push(newTag);
            container.tagsManager.universeTagging = getClearUniqueArray(tagsManager.universeTagging);
            container.createAndShowNotification("Nova tag <b style=\"color:black;\">".concat(newTag, "</b> inserida na pesquisa."), 'success');
            if (callback && typeof callback === 'function') {
              callback.apply(callbackParameters.obj, callbackParameters.parameters);
            }
            if (container.globalFilter) {
              container.globalFilter.addNewTag(newTag);
            }
          } else {
            // eslint-disable-next-line no-magic-numbers
            var isErrorMessage = result.code && result.code === -2;
            var msg = isErrorMessage ? failMessage : result.message;
            var errorType = isErrorMessage ? 'error' : 'warning';
            container.createAndShowNotification(msg, errorType);
            tagsManager.tagInsertionFailed(newTag, select2Instance);
          }
        },
        error: function error() {
          createAndShowNotification(failMessage, 'error');
        }
      });
    },
    tagExistsInUniverse: function tagExistsInUniverse(tag) {
      return tagsManager.universeTagging && tagsManager.universeTagging.length && $.inArray("".concat(tag), tagsManager.universeTagging) >= 0;
    },
    checkIfThereIsNewTag: function checkIfThereIsNewTag(filterToAnnotate, select2Instance) {
      $(filterToAnnotate.tags).each(function (index, tag) {
        if (!tagsManager.tagExistsInUniverse(tag)) {
          tagsManager.insertTagInUniverse(tag, select2Instance);
        }
      });
    },
    clearTag: function clearTag(tagsValue) {
      return tagsValue.trim().replaceAll(',+', ',').replaceAll('(, *)', ',');
    },
    getMultipleTags: function getMultipleTags(tagsValue) {
      return tagsValue.split(',');
    },
    hasMultipleTags: function hasMultipleTags(tagsValue) {
      return tagsValue.split(',').length > 1;
    },
    /**
     * Check whether the tag is a valid new tag
     * @param newTag The tag to validate
     * @returns {boolean} True if is valid, False otherwise
     */
    isNewTagValid: function isNewTagValid(newTag) {
      var errorMessage = "A tag <b>".concat(newTag, "</b>, ");
      if (newTag.length < this.minLengthTag) {
        createAndShowNotification("".concat(errorMessage, " deve conter pelo menos <b>2</b> caracteres."), 'warning');
        return false;
      }
      if (newTag.startsWith('-')) {
        createAndShowNotification("".concat(errorMessage, " n\xE3o pode come\xE7ar com <b>\"menos\"</b>."), 'warning');
        return false;
      }
      var regex = /^([ !#-&\*\+\x2D\.0-9=\?@a-z\|\xE0-\xE4\xE7-\xEF\xF2-\xF6\xF9-\xFC\u017F\u212A]+)$/i;
      if (!regex.test(newTag)) {
        createAndShowNotification("".concat(errorMessage, " pode conter apenas alguns caracteres especiais, s\xE3o eles: <b>! @ & # $ % * + - = ? |</b>."), 'warning');
        return false;
      }
      return true;
    },
    influencers: {
      tagList: [],
      tagRequest: function tagRequest(_ref3) {
        var method = _ref3.method,
          body = _ref3.body,
          successCallback = _ref3.successCallback,
          errorCallback = _ref3.errorCallback;
        var init = {
          method: method
        };
        if (body) Object.assign(init, {
          body: body
        });
        var tagRequest = new Request('/influencers/tags', init);
        fetch(tagRequest).then(function (response) {
          response.json().then(function (result) {
            successCallback(result);
          });
        })["catch"](errorCallback);
      },
      insertTagInfluencers: function insertTagInfluencers(newTag) {
        var _this = this;
        var body = new FormData();
        body.append('new_tag', newTag);
        this.tagRequest({
          method: 'POST',
          body: body,
          successCallback: function successCallback(response) {
            if (response.status === 'DATA') {
              _this.tagList.push(newTag);
            }
          },
          errorCallback: function errorCallback(error) {
            console.error(error);
          }
        });
      },
      getTags: function getTags(callback) {
        var retries = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
        if (retries > this.maxRetries) {
          tagsManager.influencers.tagList = [];
          tagsManager.influencers.tagsLoaded = true;
          return;
        }
        this.tagRequest({
          method: 'GET',
          body: false,
          successCallback: function successCallback(response) {
            var tags = [];
            if ('status' in response && response.status === 'DATA') {
              tags = response.message.tags;
              tagsManager.influencers.tagList = getClearUniqueArray(tags);
              tagsManager.influencers.tagsLoaded = true;
              return callback();
            }
            tagsManager.getTags(retries + 1);
          },
          errorCallback: function errorCallback(error) {
            console.error(error);
          }
        });
      },
      checkIfThereIsNewTagInInfluencersTable: function checkIfThereIsNewTagInInfluencersTable(tag) {
        if (!tagsManager.influencers.tagsLoaded) {
          tagsManager.influencers.getTags(function () {
            tagsManager.influencers.checkIfThereIsNewTagInInfluencersTable(tag);
          });
        } else if (!tagsManager.influencers.tagList.includes(tag)) {
          tagsManager.influencers.insertTagInfluencers(tag);
        }
      }
    },
    getSelect2TagsData: function getSelect2TagsData() {
      var select2Data = [];
      if (window.universeTagging) {
        var _iterator = _createForOfIteratorHelper(window.universeTagging),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var tag = _step.value;
            select2Data.push({
              id: tag,
              text: tag
            });
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }
      return select2Data;
    }
  };
  window.tagsManager = tagsManager;
})();